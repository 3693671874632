import React from "react"
import { Row, Col, Stack, Nav, Image } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import concept1 from "../assets/pictures/game/conceptart1.png"

const Game = () => {
  return (
    <Layout>
      <SEO title="Game Development Ideas" />
      <Row>
        <Col
          sm={"auto"}
          style={{ alignItems: "center", height: "90vh", display: "grid" }}
        >
          <Nav>
            <Stack gap={5}>
              <Nav.Item>
                <Nav.Link href="#intro">
                  <svg
                    width={"20px"}
                    height={"20px"}
                    style={{ verticalAlign: "middle" }}
                  >
                    <circle cx="10" cy="10" r="10" />
                  </svg>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link href="#story">
                  <svg width={"20px"} height={"20px"}>
                    <circle cx="10" cy="10" r="10" />
                  </svg>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link href="#characters">
                  <svg width={"20px"} height={"20px"}>
                    <circle cx="10" cy="10" r="10" />
                  </svg>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link href="#setting">
                  <svg width={"20px"} height={"20px"}>
                    <circle cx="10" cy="10" r="10" />
                  </svg>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link href="#features">
                  <svg width={"20px"} height={"20px"}>
                    <circle cx="10" cy="10" r="10" />
                  </svg>
                </Nav.Link>
              </Nav.Item>
            </Stack>
          </Nav>
        </Col>
        <Col style={{ height: "90vh", overflowY: "scroll" }}>
          <div
            className="jumbotron"
            style={{ margin: "15vh 10vh 15vh 10vh" }}
            id="intro"
          >
            <Row>
              <Col>
                <h2 className="title">Bug Hotel - Hostel Arthropod</h2>
                <p className="text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </Col>
              <Col>
                <Image src={concept1} fluid />
              </Col>
            </Row>
          </div>

          <div
            className="jumbotron"
            id="story"
            style={{ margin: "7.5vh 10vh 7.5vh 10vh" }}
          >
            <p className="subtitle">Storyline</p>
            <p className="text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>

          <div
            className="jumbotron"
            id="characters"
            style={{ margin: "7.5vh 10vh 7.5vh 10vh" }}
          >
            <p className="subtitle">Characters</p>
            <p className="text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            
            <Row className="justify-content-md-center">
              <Col md="auto">
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col md="auto">
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col style={{textAlign: "center", paddingTop: "10vh"}}>
                <h3>Playable Characters</h3>
              </Col>
            </Row>


            <Row className="justify-content-md-center">
              <Col md="auto">
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col md="auto">
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col md="auto">
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col style={{textAlign: "center", paddingTop: "10vh"}}>
                <h3>Hotel Staff</h3>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col style={{textAlign: "center", paddingTop: "10vh"}}>
                <h3>Suspicious Guests</h3>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
            </Row>
          </div>

          <div
            className="jumbotron"
            id="setting"
            style={{ margin: "7.5vh 10vh 7.5vh 10vh" }}
          >
            <p className="subtitle">Setting</p>
            <p className="text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <h3 style={{marginTop: "2rem", marginBottom: "1rem"}}>Inspiration</h3>
            <Row>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://i.insider.com/5d940dbe6f24eb6a90370663?width=700"/>
              </Col>
            </Row>
            <p className="text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <h3 style={{marginTop: "2rem", marginBottom: "1rem"}}>Concept Art</h3>
            <Row>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
              <Col>
                <Image className="polaroid" alt="stm" src="https://bulma.io/images/placeholders/128x128.png"/>
              </Col>
            </Row>
          </div>

          <div
            className="jumbotron"
            id="features"
            style={{ margin: "7.5vh 10vh 7.5vh 10vh" }}
          >
            <p className="subtitle">Game Features</p>
            <p className="text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <Row>
              <Col md="auto">
                <Image className="polaroid" alt="stm" src="https://live.staticflickr.com/7909/46628090665_42dfdddecf_o.jpg"/>
              </Col>
              <Col>
                <h4>Murder Mystery</h4>
              </Col>
            </Row>
            <Row>
              <Col md="auto">
                <Image className="polaroid" alt="stm" src="https://i1.sndcdn.com/artworks-000400027098-gf4kza-t500x500.jpg"/>
              </Col>
              <Col>
                <h4>Dating Simulation</h4>
              </Col>
            </Row>
            <Row>
              <Col md="auto">
                <Image className="polaroid" alt="stm" src="https://images.nintendolife.com/996e5f59a11ca/1280x720.jpg"/>
              </Col>
              <Col>
                <h4>Horror</h4>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Game
